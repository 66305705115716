.nav {
    background: linear-gradient(rgb(93, 95, 95),rgb(94, 94, 94),0.6);
    width:7%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    -ms-flex-direction: column;
    padding: 0.7rem,1.7rem;
    z-index: 2;
    position: fixed;
    left: 7%;
    transform: translateX(-50%);
    bottom: 2rem;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(2rem);
}

.nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 0.7rem;
}


.nav a:hover {
    background: var(--color-light);
}


.nav a.actice {
    background: var(--color-light);

}