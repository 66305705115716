
/*=========For all Elements===========*/
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}
/*=========Variables for styles===========*/

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4bd5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);
    --container-width-large: 75%;
    --container-width-md: 86%;
    --container-width-sm : 90%;
    --transitions: all 400ms ease;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

/*=========Main body===========*/


body {
font-family: "Poppins", sans-serif;
background: linear-gradient(rgb(43, 0, 104),rgba(10, 63, 100, 0.839) 20%,rgb(29, 5, 55));
color: var(--color-white);
line-height: 1.7;
}


/*=========General Style Classes===========*/

.container {
    width: var(--container-width-large);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, 
section > h5  {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light)
}



a {
color: var(--color-primary);
transition: var(--transitions);
}


a:Hover {
    color: var(--color-white);
}


.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem,1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transitions); 
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;

}


.btn-primany {
    background: var(--color-primary);
    color: var(--color-bg);
}


img {
    display: block;
    width: 100%;
    height: 75%;
    padding-top: 3rem;
    object-fit: contain;
}

/*=========Media Queries===========*/


/*=========Medium===========*/

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;

    }
}

/*=========Small===========*/

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    section > h2 {
        margin-bottom: 2rem;

    }
}

