header {

    height: 10vh;
    padding-top: 1.5rem;
    
    
}

.cta {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    font-size: larger;
}

.header__container {
    text-align: center;
    height: 100%;
}

.header__socials {
    display: flex;
    gap: 1rem;
    justify-content: center;
    font-size: larger;
    flex-direction: column;
}

.header__socials::after {
    content: '';
    height: 2rem;
    width: 1px;
    color: var(--color-primary);

}




/*=========Media Queries===========*/


/*=========Medium===========*/

@media screen and (max-width: 1024px) {
    
        header {
            height: 68vh;
        }
 }

/*=========Small===========*/

@media screen and (max-width: 600px) {
    header {
    height: 100vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
}
